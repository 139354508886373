@import 'themes-vars';

.devices-table,
.towers-table {
    color: primaryGray !important ;
    .MuiDataGrid-iconSeparator {
        display: none;
    }
    .MuiDataGrid-columnHeaderTitle {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
    }
    .MuiDataGrid-root {
        border: unset;
        .MuiDataGrid-cell {
            border-bottom: 2px solid rgba(233, 237, 242, 1);
        }
    }
}
