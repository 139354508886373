@import 'themes-vars';
@import 'plugins/plugins';
@import 'table';

html,
body,
#root {
    font-family: $fontFamily;
    height: 100%;
}
/* breadcrumb */
.icon-breadcrumb {
    margin-right: 5px;
}

.link-breadcrumb {
    font-size: 0.75rem;
    display: flex;
    text-decoration: none;
}

/* card */

.card-header {
    font-weight: 600 !important;
    font-size: 15px;
}

.apexcharts-legend-marker {
    margin-right: 8px !important;
}

.MuiCard-root {
    &.bg-primary {
        background-color: $primary;
        color: #fff;
        .MuiCardHeader-root {
            background-color: $primary;
            color: #fff;
        }
    }
    &.bg-secondary {
        background-color: $secondary;
        color: #fff;
        .MuiCardHeader-root {
            background-color: $secondary;
            color: #fff;
        }
    }
    &.bg-error {
        background-color: $error;
        color: #fff;
        .MuiCardHeader-root {
            background-color: $error;
            color: #fff;
        }
    }
    &.bg-warning {
        background-color: $warning;
        color: #fff;
        .MuiCardHeader-root {
            background-color: $warning;
            color: #fff;
        }
    }
    &.bg-info {
        background-color: $info;
        color: #fff;
        .MuiCardHeader-root {
            background-color: $info;
            color: #fff;
        }
    }
    &.bg-success {
        background-color: $success;
        color: #fff;
        .MuiCardHeader-root {
            background-color: $success;
            color: #fff;
        }
    }
    &.bg-dark {
        background-color: $dark;
        color: #fff;
        .MuiCardHeader-root {
            background-color: $dark;
            color: #fff;
        }
    }
    &.outline-primary {
        border: 1px solid + $primary;
        .MuiCardHeader-root {
            border-bottom: 1px solid + $primary;
        }
    }
    &.outline-secondary {
        border: 1px solid $secondary;
        .MuiCardHeader-root {
            border-bottom: 1px solid + $secondary;
        }
    }
    &.outline-error {
        border: 1px solid $error;
        .MuiCardHeader-root {
            border-bottom: 1px solid + $error;
        }
    }
    &.outline-warning {
        border: 1px solid $warning;
        .MuiCardHeader-root {
            border-bottom: 1px solid + $warning;
        }
    }
    &.outline-info {
        border: 1px solid $info;
        .MuiCardHeader-root {
            border-bottom: 1px solid + $info;
        }
    }
    &.outline-success {
        border: 1px solid $success;
        .MuiCardHeader-root {
            border-bottom: 1px solid + $success;
        }
    }
    &.outline-dark {
        border: 1px solid $dark;
        .MuiCardHeader-root {
            border-bottom: 1px solid + $dark;
        }
    }
}

.f-right {
    float: right;
}
.p-0 {
    padding: 0 !important;
}
.p-20 {
    padding: 20px !important;
}

.responsive-table-card {
    .MuiTableCell-root {
        white-space: unset;
    }
}

// swiper js
.swiper-button-prev,
.swiper-button-next {
    position: unset;
    color: #ffffff;
}
.swiper-slide {
    height: auto;
}
.swiper-button-prev {
    margin-right: 10px;
}

.swiper-button-next {
    margin-left: 10px;
}

//override MUI

.MuiSelect-select {
    padding-bottom: 2px !important;
    padding-top: 2px !important;
    border-radius: 4px;
}
.gate-details {
    .MuiFormControl-root fieldset,
    .MuiOutlinedInput-root fieldset,
    .MuiButton-outlined {
        border: 2px solid $textPrimary;
    }
}
.sg-input {
    .FormTextField-root,
    .MuiOutlinedInput-root {
        padding: 5px !important;
        box-sizing: border-box !important;
        border-radius: 10px !important;
        max-width: 250px;
        margin-bottom: 3px;
        background-color: #e9edf2;

        .MuiOutlinedInput-input {
            padding: 0px;
            padding-left: 5px !important;
        }
        .MuiOutlinedInput-notchedOutline {
            border: 0 !important;
        }
        .MuiSvgIcon-root {
            color: #a6a4a4;
        }
        :focus,
        :active {
            color: #000000 !important;
            background-color: #ffffff;
        }
        .MuiSelect-select {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
    }
}

// cutsom

.form-input-button {
    background: $backgoundGrey !important;
    border-radius: 10px !important;
    padding: 16px 36px !important;
    color: $textPrimary !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-transform: capitalize !important;
    height: 41px;
    margin-bottom: 15px;
    max-width: 250px;
}
.rs-picker-daterange-menu {
    color: #000000;
}

.custom-time-input {
    width: 100%;
    display: flex;
    align-items: center;
    width: fit-content;
    border-radius: 5px;
    background: $backgoundGrey !important;
    border-radius: 10px !important;

    input[type='time']::-webkit-calendar-picker-indicator {
        display: none;
    }
    fieldset {
        border: 0px;
    }
}
table .MuiTablePagination-select {
    background-color: #364861 !important;
}
table .MuiTablePagination-select option {
    background-color: #364861 !important;
}

.towerCardContent {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
}

.towerCardContent::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}
